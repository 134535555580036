<template>
  <div class="pagelist">

      <!-- <div class="notext" v-if="list.length == 0">暂无数据</div> -->
      <div class="hangbox flexspb" v-for="(item, index) in list" :key="index">
        <div  class="flexcent">
          <span class="leftspot">·</span>
          <div v-if="item.name=='深度视觉识别模型对抗性测试工具' " class="flexcent">
            <a :href="item.toolUrl" target="_blank">
              <span>{{item.name}}</span>
            </a>
          </div>
          <div v-else  class="flexcent">
            <router-link :to="{path: detailPath+'/'+item.id}" :target="target">  
              <span>{{ item.title ||item.name }}</span>
            </router-link>
          </div>
        </div>
        <div class="time flexcent" v-if="item.type">
           {{ item.type}}
        </div>
        <!-- <div class="time flexcent" v-if="item.createdTime">
           {{ item.createdTime| FormatDate('yyyy-MM-dd')}}
        </div> -->
      </div>

    <div v-if="this.total &&  this.size < this.total">
      <pagin @change="handleChange" :current="current" :size="size" :total="total" />
    </div>
  </div>
</template>

<script>
// import card from "@/components/all/card"; //封装的卡片样式
import pagin from "@/components/all/pagin"; //引入分页组件
export default {
  name: "pageList", //翻页列表
  props: {
    list: {
      typeof: [Array],
      default: [],
    },
    detailPath: {
      typeof: [String],
      default: '',
    },
    current: {
      typeof: [Number],
      // default: 1,
    },
    size: {
      typeof: [Number],
      // default: 1,
    },
    total: {
      typeof: [Number],
      // default: 1,
    },
    target: {
      typeof: [String],
      default: '_self',
      //  default: '_blank',
    }
  },

 
  components: {
    // card,
    pagin,
  },
  data() {
    return {
      // current: 0, //当前页码
      // size: 0, //每页条数
      // total: 1, //总数
    };
  },
  
  methods: {
    handleChange(e) {
      // console.log("$$$$$$$$$$$$$ page list handleChange ", e)
      this.$emit("pageChanged", e);
    },
  },
};
</script>

<style scoped lang="less">
 .pagelist {
    .notext{
      margin: 10px;
    }
    .hangbox {
      height: 46px;
      border-bottom: 1px dashed #ccc;
      padding-bottom: 10px;      
    }
    .hangbox:hover {
      background: #dce8f5 ;
    }

    .leftspot {
      padding: 0 10px 0 0;  
      font-size: 2.5rem;
    }
    .title {
      margin-top: 0.375rem;
      color: #494949;
      font-size: 0.875rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .title:hover {
      color: #3d59ff;
      text-decoration: underline;
    }
    .time {
      padding: 0 20px; 
      min-width: 100px;  
    }
  }

// @media screen and (max-width: 999px) {
//   // 当前页面宽高
//   .box {
//     padding: 0 0.9375rem;
//     margin-top: 0.625rem;
//     .time {
//       font-size: 0.675rem;
//       display: none;
//     }
//     .title {
//       // width: 12.25rem;
//       padding-right: 1.25rem;
//     }
//   }
// }

// @media screen and (min-width: 1000px) {
//   .box {
//     padding: 0 0.3125rem;
//     .time {
//       font-size: 0.875rem;
//     }
//     .title {
//       margin-top: 0.375rem;
//     }
    
//   }
// }
</style>
