<template>
  <!-- 分页样式 -->
  <div class="paginbox flexcent">
    <el-pagination
      popper-class="dropdownselect"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10,20,50,100]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "pagin", //分页
  data() {
    return {
      currentPage: 0, //当前的页码
      pagesize: 0, //每页显示的条数
    };
  },
  props: {
    // 当前的页码)
    current: {
      typeof: [Number],
    },
    // 每页条数
    size: {
      typeof: [Number],
    },
    // 总数
    total: {
      typeof: [Number],
    },
  },
  created() {
    this.pagesize = this.size;
    this.currentPage = this.current;
    // console.log(" ===== pagin   created " + this.pagesize  + this.currentPage + this.total)
  },

  // mounted(){
  //    console.log(" ===== pagin  mounted " + this.pagesize  + this.currentPage + this.total)
  //    console.log(" ===== pagin  mounted  prop " + this.size  + this.current + this.total)
  //  },
  methods: {
    //   改变条数
    handleSizeChange(e) {
      // console.log("  handleSizeChange " + e)
      this.pagesize = e;
      this.$emit("change", { current: this.currentPage, size: this.pagesize });
    },
    //   改变页数
    handleCurrentChange(e) {
        // console.log("  handleCurrentChange " + e)
      if (this.currentPage != e) {
        this.currentPage = e;
        this.$emit("change", { current: this.currentPage, size: this.pagesize });
      }
    },
  },
};
</script>

<style lang='less'>
.el-popper.dropdownselect {
  margin: 0.75rem 0;
  .el-scrollbar {
    height: 9.375rem;
    width: 6.75rem;
  }
  .popper__arrow {
    display: none;
  }
  .el-scrollbar__wrap {
    overflow: hidden;
    width: 6.75rem;
    max-height: 9.375rem;
    padding: 0.375rem 0;
    box-sizing: border-box;
    .el-select-dropdown__item {
      width: 6.75rem;
      height: 2.1875rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .selected {
      font-weight: 550;
    }
  }
}
</style>

<style scoped lang="less">
  .paginbox {
    // min-width: 700px;
    // height: 100%;
    margin: 3.125rem 0 9.375rem;

    /deep/ .el-pagination {
      height: 2rem;
      width: 800px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
      // width: 25.625rem;
      .el-pager {
        width: 10rem;
        height: 1.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          margin-left: 0.5rem;
        }
      }
      button {
        height: 1.75rem;
        width: 2.1875rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .el-pagination__jump {
        width: 5.8125rem;
        height: 1.75rem;
        display: flex;
        align-items: center;
        .el-input {
          width: 3.125rem;
          height: 1.75rem;
          margin: 0 0.3125rem;
          input {
            width: 3.125rem;
            height: 1.75rem;
          }
        }
      }
      //   下拉盒子
      .el-pagination__sizes {
        width: 6.875rem;
        height: 1.75rem;

        .el-input {
          width: 6.875rem;
          height: 1.75rem;
          font-size: 0.875rem;

          input {
            width: 6.875rem;
            height: 1.75rem;
            padding: 0 1.5625rem 0 0.9375rem;
          }
          .el-input__suffix {
            width: 1.5625rem;
            height: 1.75rem;
            .el-select__caret {
              width: 1.5625rem;
              height: 1.75rem;
            }
          }
        }
      }
    }
  }

//  @media screen and (min-width: 500px) {
// }

// @media screen and (max-width: 499px) {
//   .paginbox {
//     width: 100%;
//     height: 100%;
//     box-sizing: border-box;
//     padding: 1rem;

//     /deep/ .el-pagination {
//       height: 2rem;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       font-size: 0.875rem;
//       width: 100%;
//       .el-pager {
//         width:6rem;
//         height: 1.75rem;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         li {
//           margin-left: 0.4rem;
//         }
//       }
//       button {
//         height: 1.75rem;
//         width: 2.1875rem;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//       .el-pagination__jump {
//         width: 5.8125rem;
//         height: 1.75rem;
//         display: flex;
//         align-items: center;
//         .el-input {
//           width: 3.125rem;
//           height: 1.75rem;
//           margin: 0 0.3125rem;
//           input {
//             width: 3.125rem;
//             height: 1.75rem;
//           }
//         }
//       }
//       //   下拉盒子
//       .el-pagination__sizes {
//         width: 5rem;
//         height: 1.75rem;

//         .el-input {
//           width: 5rem;
//           height: 1.75rem;
//           font-size: 0.875rem;

//           input {
//             width: 5rem;
//             height: 1.75rem;
//             padding: 0 1.5625rem 0 0.9375rem;
//           }
//           .el-input__suffix {
//             width: 1.5625rem;
//             height: 1.75rem;
//             .el-select__caret {
//               width: 1.5625rem;
//               height: 1.75rem;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
