<template>
    <div class="search-item-message">
        <span class="label">{{label}}:</span>
        <div class="value">
            <div class="item" v-for="(item, index) in items"
                :key="index"
                :style="{color: currentValue === item.value ? '#e74c3c' : '#545454'}"
                @click="handleSearchItem(item.value)"
            >
                {{item.label}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            label:  String,
            items:  Array,
            currentValue: String,
        },
        methods: {
            handleSearchItem(val) {
                if(val != this.currentValue) {
                  this.$emit('handleSearchItem', val)
                }
            }
        },
    };
</script>
<style scoped lang="less">
// div{
//     border: 1px solid #00F;
//     padding: 10px;
//     margin: 20px;
//   }
//   span{
//     border: 3px solid #F0F;
//     padding: 10px;
//     margin: 20px;
//   }
    .search-item-message {
        display: flex;
        padding: 0px 0 15px 0;
        line-height: 1.6;
        .label {
            color: gray;
            width: 6rem;
            // text-align: right;
            // padding: 0 1rem 0 0;
        }
        .value {
            // margin-left: 1rem;
            flex: 1;
            display: inline-block;
            .item {
                padding: 0 0.5rem 0 0.5rem;
                display: inline-block;
            }
            .item:hover {
                    background-color: #e2e5f8;
                    color: #3d59ff;
                    cursor: pointer;
                    text-decoration: underline;
            }          
        }
    }
</style>
